import React, { useReducer } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Link } from 'gatsby'
import './index.css'

const stripePromise = loadStripe(process.env.GATSBY_STRIPE_PUBLISHABLE_KEY);

const formatPrice = ({ amount, currency, quantity }) => {
  const numberFormat = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency,
    currencyDisplay: 'symbol',
  });
  const parts = numberFormat.formatToParts(amount);
  let zeroDecimalCurrency = true;
  for (let part of parts) {
    if (part.type === 'decimal') {
      zeroDecimalCurrency = false;
    }
  }
  amount = zeroDecimalCurrency ? amount : amount / 100;
  const total = (quantity * amount).toFixed(2);
  return numberFormat.format(total);
};


function reducer(state, action) {
  switch (action.type) {
    case 'increment':
      return {
        ...state,
        quantity: state.quantity + 1,
        price: formatPrice({
          amount: state.basePrice,
          currency: state.currency,
          quantity: state.quantity + 1,
        }),
      };
    case 'decrement':
      return {
        ...state,
        quantity: state.quantity - 1,
        price: formatPrice({
          amount: state.basePrice,
          currency: state.currency,
          quantity: state.quantity - 1,
        }),
      };
    case 'setLoading':
      return { ...state, loading: action.payload.loading };
    case 'setError':
      return { ...state, error: action.payload.error };
    default:
      throw new Error();
  }
}

const Checkout = () => {
  const [state, dispatch] = useReducer(reducer, {
    priceId: process.env.GATSBY_APP_PRICE_ID,
    basePrice: process.env.GATSBY_BASE_PRICE,
    currency: process.env.GATSBY_APP_CURRENCY,
    quantity: 1,
    price: formatPrice({
      amount: process.env.GATSBY_BASE_PRICE,
      currency: process.env.GATSBY_APP_CURRENCY,
      quantity: 1,
    }),
    loading: false,
    error: null,
  });

  const handleClick = async (event) => {
    // Call your backend to create the Checkout session.
    dispatch({ type: 'setLoading', payload: { loading: true } });
    // When the customer clicks on the button, redirect them to Checkout.
    const stripe = await stripePromise;
    const { error } = await stripe.redirectToCheckout({
      mode: 'payment',
      lineItems: [{ price: state.priceId, quantity: state.quantity, adjustable_quantity: {
        enabled: true,
      }}],
      successUrl: `${window.location.origin}/success?session_id={CHECKOUT_SESSION_ID}`,
      cancelUrl: `${window.location.origin}/checkout/`,
    });
    // If `redirectToCheckout` fails due to a browser or network
    // error, display the localized error message to your customer
    // using `error.message`.
    if (error) {
      dispatch({ type: 'setError', payload: { error } });
      dispatch({ type: 'setLoading', payload: { loading: false } });
    }
  };

  return (
    <div className="sr-root">
      <div className="sr-main">
        <header className="sr-header is-centered">
            <Link to='/'><img src={require('../../assets/img/ppe4all.png')} width='90' /></Link>
        </header>
        <section className="srcontainer">
          <div>
            <h1 className="sr-h1">Face Shields</h1>
            <h4 className="sr-h4">Donate a face shield to a worker in need.</h4>
            <div className="pasha-image">
              <img
                alt="Face Shield"
                src={'../img/PPE4NYC_Shields.png'}
                width="300"
                height="300"
              />
            </div>
          </div>

          <button id="checkoutbutton" role="link" onClick={handleClick} disabled={state.loading}>
            {state.loading || !state.price
              ? `Loading...`
              : `Donate`}
          </button>
          <div className="sr-field-error">{state.error?.message}</div>
        </section>
      </div>
    </div>
  );
};

export default Checkout;
