import React from 'react'
import StripeCheckoutComp from '../components/StripeCheckout'

const StripeCheckout = () => {

  return (
      <StripeCheckoutComp
      />
  )
}

export default StripeCheckout